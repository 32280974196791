.pad-content{
    padding: 10px;
    .card{
        padding: 5px;
        // border-radius: 20px;
        .itm-content{
            background-color:#eeeeee;
            padding: 10px;
            border-radius: 10px;
        }
        .desk{
            font-size: 12px;
        }
    }

    .line {
        border: 1px solid rgb(168, 165, 165);
    }

}
.modal-card {
    position: fixed;
    bottom: 40%;
    background-color: rgb(241, 240, 240);
    width: 95%;
    max-width: 620px;
    border-radius: 20px;
    .contents {
        padding: 10px;
    }
}