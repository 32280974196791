
.padding {
    padding-left: 15px;
    padding-right: 0;
    padding-bottom: 10px;
    height: 30%

}

.padding-fixed {
    padding-left: 15px;
    padding-right: 0;
    padding-bottom: 10px;
    height: 30%;
    position: fixed;
    width: 100%;
    max-width: 640px;
    z-index: 9999;
}

.back-fix {
    position: fixed;
    width: 100%;
    max-width: 640px;
    z-index: 9999;
}
.no-padd {
    padding: 0%
}
