.padd-fix {
    position: fixed
}

.content-history {
    padding: 10px;
    .round {
        border-radius: 10px;
        padding-top: 10px;
        padding-left: 10px;

        .beli{
            padding-top: 10px;
            color: green;
            font-size: 15px;
        }
    }
}