.bg {
  /* background: #c5ddbb; */
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  background-color: aliceblue;
  /* Set up positioning */
}

.bg-img {
  position: fixed;
  top: 0;
  left: 0;
  background: url("./asset/img/bg_sayur.jpg") 100% 100%;

  /* Full height */
  height: 100%;
  width: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-color {
  position: fixed;
  top: 0;
  left: 0;
  background-color: aliceblue;

  /* Full height */
  height: 100%;
  width: 100%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mobile {
  width: 100%;
  max-width: 640px;
  background-color: aliceblue;
  /* position: fixed; */
  z-index: 99;
  min-height: 100%;
  height: 100%;
}