.content{
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;

}
.logins{
    text-align: center;
}
.btn {
    width: 100%;
    font-weight: bold;
}

.small {
    padding: 40px 0px 0px 0px;
    font-size: 12px;
    color: blue;
    .boxs {
        border: 1px solid green;
        padding: 10px;
        border-radius: 10px;
    }
}
