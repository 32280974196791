.padd-fix{
    position: fixed
}

.content-home{
    padding: 10px;
    .round{
        border-radius: 10px;
        padding: 10px;
        position: relative;
        min-height: 100px;
        // text-align: right;
        .icn {
            position: absolute;
            top: 50%;
            left: 15%;
            transform: translate(-50%, -50%);
        }
        // .divid{
        //     border: 1.5px solid green;
        // }
        .divid {
            margin: .5em;
            border-width: 2px;
            border: 0.1px solid green;
        }

    }
    .round-img {
        text-align: center;
        .imgs{
            border-radius: 10px;
            width: 100%;
            height: 100%;
            max-height: 150px;
        }
    }
}
.padding{
    padding: 5px;
}
