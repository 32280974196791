.nav-padding{
    .btn-back{
        text-align: center;
        width: 10%;
        .icns{
            padding-left: 10px;
        }
    }
    .input-ctn{
        width: 90%
    }
}

#btm-card{
    // display: none;
    position: fixed;
    bottom: 0%;
    width: 100%;
    z-index: 9999;
    max-width: 640px;
    .card-radius{
        border-radius: 15px;
    }
    .box-input{
        border: aqua;
        text-align: center;
    }
    .text-title{
        font-size: 20px;
        font-weight: bold;
    }
}

.btn-keranjang{
    float: right;
    right: 1%;
    top: -50%;
    // padding: 10px;
    // border-radius: 30%;
    // border-style: solid;
    // border-width: 2px;
    // opacity: 0.1;
    // position: fixed;
    // top: 10%;
    // width: 100%;
    // max-width: 640px;
    .titles{
        font-size: 12px;
        font-weight: bold;
        color: white;
        // position: fixed;
        // top: -100px;
        // text-align: left;
        // vertical-align: -80%;
    }

}