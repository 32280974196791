.padd-fix {
    position: fixed;
    z-index: 9999;
}

.content-akun {
    padding: 10px;

    .round {
        border-radius: 20px;
        padding: 40px;

        .imgs {
            width: 35%;
        }
    }
}

.foot-fix {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 640px;
}